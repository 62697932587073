/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BicepBand } from '../models/BicepBand';
import type { ImageUploadRequest } from '../models/ImageUploadRequest';
import type { ProductTranslations } from '../models/ProductTranslations';
import type { WristBand } from '../models/WristBand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BackfillResourceService {

    /**
     * backfillAccessory
     * Backfills an accessory product
     * @returns void
     * @throws ApiError
     */
    public static backfillAccessory({
        locale,
        requestBody,
    }: {
        /**
         * The Locale for the localized strings used for the Product name, description, etc.
         */
        locale: any,
        /**
         * The details of the accessory to backfill
         */
        requestBody: BicepBand,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/accessory',
            query: {
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * backfillBattery
     * Backfills a battery product
     * @returns void
     * @throws ApiError
     */
    public static backfillBattery({
        locale,
        requestBody,
    }: {
        /**
         * The Locale for the localized strings used for the Product name, description, etc.
         */
        locale: any,
        /**
         * The details of the battery to backfill
         */
        requestBody: BicepBand,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/battery',
            query: {
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * backfillBicepBand
     * Backfills a bicep band product
     * @returns void
     * @throws ApiError
     */
    public static backfillBicepBand({
        locale,
        requestBody,
    }: {
        /**
         * The Locale for the localized strings used for the Product name, description, etc.
         */
        locale: any,
        /**
         * The details of the bicep band to backfill
         */
        requestBody: BicepBand,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/bicep_band',
            query: {
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * backfillBoxer
     * Backfills a boxer product
     * @returns void
     * @throws ApiError
     */
    public static backfillBoxer({
        locale,
        requestBody,
    }: {
        /**
         * The Locale for the localized strings used for the Product name, description, etc.
         */
        locale: any,
        /**
         * The details of the boxer to backfill
         */
        requestBody: BicepBand,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/boxer',
            query: {
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * backfillClothing
     * Backfills a clothing product
     * @returns void
     * @throws ApiError
     */
    public static backfillClothing({
        locale,
        requestBody,
    }: {
        /**
         * The Locale for the localized strings used for the Product name, description, etc.
         */
        locale: any,
        /**
         * The details of the clothing to backfill
         */
        requestBody: BicepBand,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/clothing',
            query: {
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * backfillProductTranslations
     * Backfills product translations
     * @returns void
     * @throws ApiError
     */
    public static backfillProductTranslations({
        requestBody,
    }: {
        /**
         * The details of the product name translations request
         */
        requestBody: ProductTranslations,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/name-translations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Product not found`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * backfillVariantImage
     * Backfills an image from a URL to the CommerceTools CDN as a product variant image
     * @returns void
     * @throws ApiError
     */
    public static backfillVariantImage({
        requestBody,
    }: {
        /**
         * The details of the image upload request
         */
        requestBody: ImageUploadRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/image',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * backfillWristBand
     * Backfills a wrist band product
     * @returns void
     * @throws ApiError
     */
    public static backfillWristBand({
        locale,
        requestBody,
    }: {
        /**
         * The Locale for the localized strings used for the Product name, description, etc.
         */
        locale: any,
        /**
         * The details of the wrist band to backfill
         */
        requestBody: WristBand,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/wrist_band',
            query: {
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * backfillWywBand
     * Backfills a WYW band component product
     * @returns void
     * @throws ApiError
     */
    public static backfillWywBand({
        locale,
        requestBody,
    }: {
        /**
         * The Locale for the localized strings used for the Product name, description, etc.
         */
        locale: any,
        /**
         * The details of the WYW band to backfill
         */
        requestBody: BicepBand,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/wyw_band',
            query: {
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * backfillWywClasp
     * Backfills a WYW clasp component product
     * @returns void
     * @throws ApiError
     */
    public static backfillWywClasp({
        locale,
        requestBody,
    }: {
        /**
         * The Locale for the localized strings used for the Product name, description, etc.
         */
        locale: any,
        /**
         * The details of the WYW clasp to backfill
         */
        requestBody: BicepBand,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/wyw_clasp',
            query: {
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * backfillWywFastLink
     * Backfills a WYW fast link component product
     * @returns void
     * @throws ApiError
     */
    public static backfillWywFastLink({
        locale,
        requestBody,
    }: {
        /**
         * The Locale for the localized strings used for the Product name, description, etc.
         */
        locale: any,
        /**
         * The details of the WYW fast link to backfill
         */
        requestBody: BicepBand,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/wyw_fastlink',
            query: {
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * backfillWywHook
     * Backfills a WYW hook component product
     * @returns void
     * @throws ApiError
     */
    public static backfillWywHook({
        locale,
        requestBody,
    }: {
        /**
         * The Locale for the localized strings used for the Product name, description, etc.
         */
        locale: any,
        /**
         * The details of the WYW hook to backfill
         */
        requestBody: BicepBand,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/backfill/wyw_hook',
            query: {
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * resetProductVariantImages
     * Deletes all product variant images for a CommerceTools Product
     * @returns void
     * @throws ApiError
     */
    public static resetProductVariantImages({
        productKey,
    }: {
        productKey: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/backfill/images/{productKey}/reset',
            path: {
                'productKey': productKey,
            },
            errors: {
                401: `Invalid Authorization`,
                404: `Product not found`,
                500: `Unexpected server error`,
            },
        });
    }

}
