/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AsyncCheckoutRequest } from '../models/AsyncCheckoutRequest';
import type { AsyncCheckoutResponse } from '../models/AsyncCheckoutResponse';
import type { CheckoutRequest } from '../models/CheckoutRequest';
import type { CheckoutResponse } from '../models/CheckoutResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CheckoutResourceService {

    /**
     * Checkout
     * Checkout cart from the Join Flow.
     * @returns CheckoutResponse Success
     * @throws ApiError
     */
    public static checkout({
        cfConnectingIp,
        userAgent,
        requestBody,
    }: {
        cfConnectingIp?: string,
        userAgent?: string,
        requestBody?: CheckoutRequest,
    }): CancelablePromise<CheckoutResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/join-flow/checkout',
            headers: {
                'CF-Connecting-IP': cfConnectingIp,
                'User-Agent': userAgent,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Failed to verify Turnstile`,
                422: `Payment failure.`,
                500: `Something went wrong.`,
            },
        });
    }

    /**
     * Checkout Async
     * Checkout cart from the Join Flow. This request does not immediately charge the customer. Instead a payment intent is created and returned to the client.
     * @returns AsyncCheckoutResponse Success
     * @throws ApiError
     */
    public static checkoutAsync({
        cfConnectingIp,
        userAgent,
        requestBody,
    }: {
        cfConnectingIp?: string,
        userAgent?: string,
        requestBody?: AsyncCheckoutRequest,
    }): CancelablePromise<AsyncCheckoutResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/join-flow/checkout/async',
            headers: {
                'CF-Connecting-IP': cfConnectingIp,
                'User-Agent': userAgent,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Failed to verify Turnstile`,
                500: `Something went wrong.`,
            },
        });
    }

}
