import { PriceSchema } from './PriceSchema';
import { z } from 'zod';

export const CartItemSchema = z.object({
  sku: z.string(),
  key: z.string().nullable(),
  line_item_id: z.string(),
  product_type: z.string(),
  quantity: z.number(),
  min_quantity: z.number(),
  max_quantity: z.number(),
  custom_title: z.string(),
  custom_description: z.array(z.string()),
  image_urls: z.array(z.string()),
  price: PriceSchema,
});
