/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MobileAsyncCheckoutRequest } from '../models/MobileAsyncCheckoutRequest';
import type { MobileAsyncCheckoutResponse } from '../models/MobileAsyncCheckoutResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileCheckoutResourceService {

    /**
     * Mobile Checkout Async
     * Checkout cart from the Mobile Join Flow. This request does not immediately charge the customer. Instead a payment intent is created and returned to the client.
     * @returns MobileAsyncCheckoutResponse Success
     * @throws ApiError
     */
    public static mobileCheckoutAsync({
        requestBody,
    }: {
        requestBody?: MobileAsyncCheckoutRequest,
    }): CancelablePromise<MobileAsyncCheckoutResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/mobile/join-flow/checkout/async',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Something went wrong`,
                500: `Something went wrong.`,
            },
        });
    }

}
