/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from '../models/Address';
import type { MobileCart } from '../models/MobileCart';
import type { MobileShippingInfo } from '../models/MobileShippingInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileCartResourceService {

    /**
     * addTrialToCart
     * Initializes the cart with a trial and adds to cart
     * @returns MobileCart BFF Content for the mobile join flow's cart
     * @throws ApiError
     */
    public static addTrialToCart(): CancelablePromise<MobileCart> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/mobile/join-flow/cart/trial',
            errors: {
                422: `Malformed request`,
            },
        });
    }

    /**
     * getShippingInfo
     * Get shipping and tax info for the given cart
     * @returns MobileShippingInfo BFF Content for the mobile join flow's Apple Pay sheet
     * @throws ApiError
     */
    public static getShippingInfo({
        cartId,
        requestBody,
    }: {
        cartId: string,
        requestBody?: Address,
    }): CancelablePromise<MobileShippingInfo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/mobile/join-flow/cart/{cartId}/shipping-info',
            path: {
                'cartId': cartId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Something went wrong`,
                422: `Malformed request`,
            },
        });
    }

    /**
     * setMobileShippingAddress
     * Sets final shipping address for the given mobile cart after selecting checkout
     * @returns any Successfully set shipping address on cart
     * @throws ApiError
     */
    public static setMobileShippingAddress({
        cartId,
        requestBody,
    }: {
        cartId: string,
        requestBody?: Address,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/mobile/join-flow/cart/{cartId}/shipping-address',
            path: {
                'cartId': cartId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Something went wrong`,
                404: `Cart not found`,
                422: `Bad Request`,
            },
        });
    }

}
