import { CentPrecisionMoneySchema } from './CentPrecisionMoneySchema';
import { z } from 'zod';

export const ShippingMethodItemSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  value: CentPrecisionMoneySchema,
  selected: z.boolean(),
});
