/* eslint-disable @typescript-eslint/no-explicit-any */

import type { Currency } from '@whoop/i18n';
import { formatCartPrice } from '@whoop/i18n';
import type {
  BillingItem,
  CartResponse,
  CartItem,
} from 'services/generated/commerce-service';
import { CartResponseSchema } from 'services/generated/commerce-service/schemas/CartResponseSchema';
import type {
  ParsedCartDTO,
  ParsedCartItem,
  ParsedBillingItem,
  ParsedShippingMethod,
  ParsedCentPrecisionMoney,
  ParsedDiscountedPrice,
  BillingItemType,
} from 'ui/types/cartTypes';
import analytics from 'whoop-analytics';

export function parseCart(cartResponse: CartResponse): ParsedCartDTO {
  const zodResult = CartResponseSchema.safeParse(cartResponse);
  if (!zodResult.success) {
    try {
      analytics.trackError?.(
        `Failed to parse cart ${JSON.stringify(zodResult.error)}`,
      );
    } catch (err) {
      // Swallow error
    }
    return customParseCart(cartResponse);
  }
  return customParseCart(zodResult.data);
}

// --------------- CUSTOM CART PARSER --------------- //

export function customParseCart(cart: any): ParsedCartDTO {
  return {
    id: getCartId(cart),
    cartItems: getCartItems(cart),
    billingDetails: getBillingDetails(cart),
    shippingMethodItems: getShippingMethodItems(cart),
  };
}

// Cart ID:
export function getCartId(cart: any): string {
  return cart?.id ?? '';
}

// Cart Items:
export function getCartItems(cart: any): ParsedCartItem[] {
  return (
    cart?.cart_items?.map((item) => ({
      id: getCartItemId(item),
      title: getCartItemTitle(item),
      imageUrls: getCartItemImageUrls(item),
      originalPrice: getCartItemOriginalPrice(item),
      discountedPrice: getCartItemDiscountPrice(item),
      description: getCartItemDescription(item),
      quantity: getCartItemQuantity(item),
      sku: getCartItemSku(item),
      key: getCartItemKey(item),
      productType: getCartItemProductType(item),
      minQuantity: getCartItemMinQuantity(item),
      maxQuantity: getCartItemMaxQuantity(item),
    })) ?? []
  );
}

export function turnCartItemsToParsedCartItems(
  cartItems: CartItem[],
): ParsedCartItem[] {
  return cartItems.map((item) => ({
    id: getCartItemId(item),
    title: getCartItemTitle(item),
    imageUrls: getCartItemImageUrls(item),
    originalPrice: getCartItemOriginalPrice(item),
    discountedPrice: getCartItemDiscountPrice(item),
    description: getCartItemDescription(item),
    quantity: getCartItemQuantity(item),
    sku: getCartItemSku(item),
    key: getCartItemKey(item),
    productType: getCartItemProductType(item),
    minQuantity: getCartItemMinQuantity(item),
    maxQuantity: getCartItemMaxQuantity(item),
  }));
}

export function getCartItemId(item: any): string {
  return item?.line_item_id ?? '';
}

export function getCartItemTitle(item: any): string {
  return item?.custom_title ?? '';
}

export function getCartItemImageUrls(item: any): string[] {
  return item?.image_urls ?? [];
}

export function getCartItemOriginalPrice(item: any): ParsedCentPrecisionMoney {
  return {
    centAmount: item?.price?.value?.cent_amount ?? 0,
    currencyCode: item?.price?.value?.currency_code ?? 'USD',
  };
}

export function getCartItemDiscountPrice(
  item: any,
): ParsedDiscountedPrice | null {
  if (!item?.price?.discounted) return null;
  return {
    value: {
      centAmount: item.price.discounted.value.cent_amount ?? 0,
      currencyCode: item.price.discounted.value.currency_code ?? 'USD',
    },
  };
}

export function getCartItemDescription(item: any): string[] {
  return item?.custom_description ?? [];
}

export function getCartItemQuantity(item: any): number {
  return item?.quantity ?? 0;
}

export function getCartItemSku(item: any): string {
  return item?.sku ?? '';
}

export function getCartItemKey(item: any): string {
  return item?.key ?? '';
}

export function getCartItemProductType(item: any): string {
  return item?.product_type ?? '';
}

export function getCartItemMinQuantity(item: any): number {
  return item?.min_quantity ?? 0;
}

export function getCartItemMaxQuantity(item: any): number {
  return item?.max_quantity ?? 10;
}

// Billing Details:

export function getBillingDetails(cart: any): ParsedBillingItem[] {
  return (
    cart?.billing_details?.map((billingItem) => ({
      title: getBillingItemTitle(billingItem),
      value: getBillingItemValue(billingItem),
      type: getBillingItemType(billingItem),
    })) ?? []
  );
}

export function turnBillingDetailsToParsedBillingDetails(
  billingDetails: BillingItem[],
): ParsedBillingItem[] {
  return (
    billingDetails?.map((billingItem) => ({
      title: getBillingItemTitle(billingItem),
      value: getBillingItemValue(billingItem),
      type: getBillingItemType(billingItem),
    })) ?? []
  );
}

export function getBillingItemTitle(billingItem: any): string {
  return billingItem?.title ?? '';
}

export function getBillingItemValue(
  billingItem: any,
): ParsedCentPrecisionMoney | null {
  if (!billingItem.value) return null;
  return {
    centAmount: billingItem?.value?.cent_amount ?? 0,
    currencyCode: billingItem?.value?.currency_code ?? 'USD',
  };
}

export function getBillingItemType(billingItem: any): BillingItemType {
  return billingItem?.type ?? '';
}
// Shared Parser Utils:

export function formatCentPrecisionMoney(value: any): string {
  return value?.cent_amount && value?.currency_code
    ? formatCartPrice(value.cent_amount, value.currency_code as Currency)
    : '';
}

export function getShippingMethodItems(cart: any): ParsedShippingMethod[] {
  return (
    cart?.shipping_method_items?.map((item) => ({
      id: getShippingMethodId(item),
      title: getShippingMethodTitle(item),
      description: getShippingMethodDescription(item),
      price: getShippingMethodPrice(item),
      isSelected: getShippingMethodIsSelected(item),
    })) ?? []
  );
}

export function getShippingMethodId(shippingMethod: any): string {
  return shippingMethod?.id ?? '';
}

export function getShippingMethodTitle(shippingMethod: any): string {
  return shippingMethod?.title ?? '';
}

export function getShippingMethodDescription(shippingMethod: any): string {
  return shippingMethod?.description ?? '';
}

export function getShippingMethodPrice(
  shippingMethod: any,
): ParsedCentPrecisionMoney {
  return {
    centAmount: shippingMethod?.value?.cent_amount ?? 0,
    currencyCode: shippingMethod?.value?.currency_code ?? 'USD',
  };
}

export function getShippingMethodIsSelected(shippingMethod: any): string {
  return shippingMethod?.selected ?? false;
}
