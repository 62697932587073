/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataSubjectRequest } from '../models/DataSubjectRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TranscendResourceV1Service {

    /**
     * @returns any default response
     * @throws ApiError
     */
    public static webhook1({
        requestBody,
        xSombraToken,
        xTranscendNonce,
    }: {
        requestBody: DataSubjectRequest,
        xSombraToken?: string,
        xTranscendNonce?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/transcend/webhook',
            headers: {
                'x-sombra-token': xSombraToken,
                'x-transcend-nonce': xTranscendNonce,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
