/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ItemInventorySyncRequest } from '../models/ItemInventorySyncRequest';
import type { ItemSyncRequest } from '../models/ItemSyncRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ItemSyncResourceService {

    /**
     * syncItem
     * End point for ERP integration to use to synchronize the prices for a given item into the ecommerce platform
     * @returns void
     * @throws ApiError
     */
    public static syncItem({
        sku,
        requestBody,
    }: {
        /**
         * The SKU that uniquely identifies the item to synchronize to the ecommerce platform
         */
        sku: string,
        requestBody: ItemSyncRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sync/v1/items/{sku}',
            path: {
                'sku': sku,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                404: `Product variant not found for given SKU`,
                409: `Conflict. CommerceTools uses optimistic locking. If the update operation fails this check, 409 (Conflict) will be thrown. Clients should retry the operation.`,
                422: `Unprocessable Entity. Returned if the request body does not match the JSON specification.`,
                500: `Unexpected server error`,
            },
        });
    }

    /**
     * syncItemInventory
     * End point for ERP integration to use to synchronize the current inventory available for an item into the ecommerce platform
     * @returns void
     * @throws ApiError
     */
    public static syncItemInventory({
        sku,
        location,
        requestBody,
    }: {
        /**
         * The SKU that uniquely identifies the item to synchronize to the ecommerce platform
         */
        sku: string,
        /**
         * The unique identifier for the ERP location where inventory is available. This location identifier must uniquely represent a location. For MVP, the development team will manually set up CommerceTools inventory sources for agreed upon location identifiers in a way that the location  identifier can correctly map to them.
         */
        location: string,
        requestBody: ItemInventorySyncRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sync/v1/items/{sku}/inventory/{location}',
            path: {
                'sku': sku,
                'location': location,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid Authorization`,
                404: `Not Found. Returned if no product variant is found for the given SKU`,
                422: `Unprocessable Entity. Returned if the request body does not match the JSON specification.`,
                500: `Unexpected server error. This includes networking issues, downstream server configuration problems, and unknown locations in the request body.`,
            },
        });
    }

}
