/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddDiscountToCartRequest } from '../models/AddDiscountToCartRequest';
import type { AddEngravingsToCartRequest } from '../models/AddEngravingsToCartRequest';
import type { AddPackToCartRequest } from '../models/AddPackToCartRequest';
import type { Address } from '../models/Address';
import type { AddToCartRequest } from '../models/AddToCartRequest';
import type { AddWywToCartRequest } from '../models/AddWywToCartRequest';
import type { CartResponse } from '../models/CartResponse';
import type { SetCartItemQuantityRequest } from '../models/SetCartItemQuantityRequest';
import type { SetCustomerEmailRequest } from '../models/SetCustomerEmailRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CartResourceService {

    /**
     * addDiscountToCart
     * Initializes the cart (if needed) and adds discount code
     * @returns CartResponse A created cart with discount code
     * @throws ApiError
     */
    public static addDiscountToCart({
        requestBody,
    }: {
        requestBody?: AddDiscountToCartRequest,
    }): CancelablePromise<CartResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/join-flow/cart/discount',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Malformed request`,
            },
        });
    }

    /**
     * addEngravingsToCart
     * Initializes the cart (if needed) and adds engraving line item to cart
     * @returns CartResponse A created cart with engraving line items
     * @throws ApiError
     */
    public static addEngravingsToCart({
        requestBody,
    }: {
        requestBody?: AddEngravingsToCartRequest,
    }): CancelablePromise<CartResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/join-flow/cart/engraving',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Malformed request`,
            },
        });
    }

    /**
     * addPackToCart
     * Initializes the cart (if needed) and adds a pack line item to cart
     * @returns CartResponse A created cart with pack line items
     * @throws ApiError
     */
    public static addPackToCart({
        requestBody,
    }: {
        requestBody?: AddPackToCartRequest,
    }): CancelablePromise<CartResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/join-flow/cart/pack',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request, such as a quantity < 1 or child items that do not match the pack configuration`,
                422: `Malformed request`,
            },
        });
    }

    /**
     * addToCart
     * Initializes the cart (if needed) and adds item to cart
     * @returns CartResponse A created cart with any initialized line items
     * @throws ApiError
     */
    public static addToCart({
        recalculateTax = false,
        requestBody,
    }: {
        recalculateTax?: boolean,
        requestBody?: AddToCartRequest,
    }): CancelablePromise<CartResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/join-flow/cart/line-item',
            query: {
                'recalculateTax': recalculateTax,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Malformed request`,
            },
        });
    }

    /**
     * addWywToCart
     * Initializes the cart (if needed) and adds wyw line item to cart
     * @returns CartResponse A created cart with wyw line items
     * @throws ApiError
     */
    public static addWywToCart({
        requestBody,
    }: {
        requestBody?: AddWywToCartRequest,
    }): CancelablePromise<CartResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/join-flow/cart/wyw',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Malformed request`,
            },
        });
    }

    /**
     * getCartById
     * Gets cart by id
     * @returns any Cart
     * @throws ApiError
     */
    public static getCartById({
        cartId,
    }: {
        cartId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/join-flow/cart/{cartId}',
            path: {
                'cartId': cartId,
            },
            errors: {
                404: `Cart not found`,
                500: `Server error`,
            },
        });
    }

    /**
     * setCustomerEmail
     * Set customer email for the given cart
     * @returns CartResponse An updated cart with customer email set
     * @throws ApiError
     */
    public static setCustomerEmail({
        cartId,
        requestBody,
    }: {
        cartId: string,
        requestBody?: SetCustomerEmailRequest,
    }): CancelablePromise<CartResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/join-flow/cart/{cartId}/customer-email',
            path: {
                'cartId': cartId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Cart not found`,
            },
        });
    }

    /**
     * setShippingAddress
     * Set shipping address for the given cart
     * @returns CartResponse An updated cart with shipping address and default shipping method set
     * @throws ApiError
     */
    public static setShippingAddress({
        cartId,
        requestBody,
    }: {
        cartId: string,
        requestBody?: Address,
    }): CancelablePromise<CartResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/join-flow/cart/{cartId}/shipping-address',
            path: {
                'cartId': cartId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Cart not found`,
                422: `Bad Request`,
            },
        });
    }

    /**
     * setShippingMethod
     * Set shipping method for cart
     * @returns CartResponse An updated cart with shipping address and default shipping method set
     * @throws ApiError
     */
    public static setShippingMethod({
        cartId,
        shippingMethodId,
    }: {
        cartId: string,
        shippingMethodId: string,
    }): CancelablePromise<CartResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/join-flow/cart/{cartId}/shipping-method/{shippingMethodId}',
            path: {
                'cartId': cartId,
                'shippingMethodId': shippingMethodId,
            },
            errors: {
                400: `Shipping method id does not exist`,
                404: `Cart not found`,
            },
        });
    }

    /**
     * updateLineItemQuantity
     * Sets the line item quantity for the given line item ID
     * @returns CartResponse An updated cart incorporating the quantity adjustment
     * @throws ApiError
     */
    public static updateLineItemQuantity({
        cartId,
        lineItemId,
        requestBody,
    }: {
        cartId: string,
        lineItemId: string,
        requestBody?: SetCartItemQuantityRequest,
    }): CancelablePromise<CartResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/join-flow/cart/{cartId}/line-items/{lineItemId}/quantity',
            path: {
                'cartId': cartId,
                'lineItemId': lineItemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `A quantity less than 0 was provided`,
            },
        });
    }

}
