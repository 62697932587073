import { CartItemSchema } from './CartItemSchema';
import { ShippingMethodItemSchema } from './ShippingMethodItemSchema';
import { BillingItemSchema } from './BillingItemSchema';
import { z } from 'zod';

export const CartResponseSchema = z.object({
  id: z.string(),
  cart_items: z.array(CartItemSchema),
  shipping_method_items: z.array(ShippingMethodItemSchema),
  shipping_address: z.any(),
  billing_details: z.array(BillingItemSchema),
  billing_address: z.any(),
});
