/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PersonalDataDefinitionResponse } from '../models/PersonalDataDefinitionResponse';
import type { PersonalDataRequest } from '../models/PersonalDataRequest';
import type { PersonalDataResponse } from '../models/PersonalDataResponse';
import type { PostgresSchemaAndCoverageResponse } from '../models/PostgresSchemaAndCoverageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PrivacyServiceSpiService {

    /**
     * @returns PersonalDataDefinitionResponse default response
     * @throws ApiError
     */
    public static getPersonalDataDefinitions(): CancelablePromise<PersonalDataDefinitionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/privacy',
        });
    }

    /**
     * @returns PostgresSchemaAndCoverageResponse default response
     * @throws ApiError
     */
    public static getPostgresSchemaAndCoverage(): CancelablePromise<PostgresSchemaAndCoverageResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/privacy/schema',
        });
    }

    /**
     * @returns PersonalDataResponse default response
     * @throws ApiError
     */
    public static processPersonalDataRequest({
        requestBody,
    }: {
        requestBody?: PersonalDataRequest,
    }): CancelablePromise<PersonalDataResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/privacy/request',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
