import { CentPrecisionMoneySchema } from './CentPrecisionMoneySchema';
import { z } from 'zod';

export const BillingItemSchema = z.object({
  title: z.string(),
  value: CentPrecisionMoneySchema.optional().nullable(),
  type: z
    .union([
      z.literal('SUBTOTAL'),
      z.literal('DISCOUNT'),
      z.literal('SHIPPING'),
      z.literal('TAX'),
      z.literal('INCLUDED_TAX'),
      z.literal('TOTAL'),
    ])
    .optional(),
});
