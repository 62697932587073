/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessoryCategory } from '../models/AccessoryCategory';
import type { CatalogResponse } from '../models/CatalogResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CatalogResourceService {

    /**
     * Get Catalog
     * Fetches Join Flow Catalog
     * @returns CatalogResponse Success
     * @throws ApiError
     */
    public static getCatalog({
        country,
        language,
        promoCode,
    }: {
        country: string,
        language: string,
        promoCode?: string,
    }): CancelablePromise<CatalogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/join-flow/catalog',
            query: {
                'country': country,
                'language': language,
                'promoCode': promoCode,
            },
            errors: {
                500: `Something went wrong when trying to get the products`,
            },
        });
    }

    /**
     * Get WYW
     * Get WYW
     * @returns AccessoryCategory Success
     * @throws ApiError
     */
    public static getWhoopYourWayCatalog({
        country,
        language,
    }: {
        country: string,
        language: string,
    }): CancelablePromise<Array<AccessoryCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/join-flow/wyw',
            query: {
                'country': country,
                'language': language,
            },
            errors: {
                500: `Something went wrong when trying to get the products`,
            },
        });
    }

}
