/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostgresTableDoc } from '../models/PostgresTableDoc';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DataDocsResourceV1Service {

    /**
     * @returns PostgresTableDoc default response
     * @throws ApiError
     */
    public static getPostgresDataDocs(): CancelablePromise<Record<string, Array<PostgresTableDoc>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/datadocs/postgres',
        });
    }

}
