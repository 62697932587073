/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReceiptContentResponse } from '../models/ReceiptContentResponse';
import type { ReceiptOrderStatusResponse } from '../models/ReceiptOrderStatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReceiptResourceService {

    /**
     * Get Receipt Content
     * Fetches member receipt content for a given cart post-payment. Utilized for redirected payment types when member gets directed to receipt page with no ideation of state
     * @returns ReceiptContentResponse Success
     * @throws ApiError
     */
    public static getReceiptContent({
        cartId,
    }: {
        cartId?: string,
    }): CancelablePromise<ReceiptContentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/join-flow/receipt/content',
            query: {
                'cartId': cartId,
            },
            errors: {
                404: `Cart does not exist`,
            },
        });
    }

    /**
     * Get Receipt Page Order Status URL
     * Fetches order status URL to be displayed on receipt page
     * @returns ReceiptOrderStatusResponse Success
     * @throws ApiError
     */
    public static getReceiptOrderStatusUrl({
        cartId,
        paymentIntentId,
    }: {
        cartId?: string,
        paymentIntentId?: string,
    }): CancelablePromise<ReceiptOrderStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/join-flow/receipt/order-status-url',
            query: {
                'cartId': cartId,
                'paymentIntentId': paymentIntentId,
            },
            errors: {
                404: `Either the cart or PaymentIntent does not exist`,
            },
        });
    }

}
